var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".nxin-help-20JUlb{width:72px;position:fixed;right:10px;bottom:170px;border:1px solid #e9e9e9;font-size:12px;text-align:center;font-family:\"Microsoft YaHei\";z-index:999;background-color:#fff}.nxin-help-20JUlb *{margin:0;padding:0}.nxin-help-20JUlb *:focus{outline:none}.nxin-help-20JUlb a{display:block;color:#666;cursor:pointer;background:#fff url(" + escape(require("../images/help.png")) + ") no-repeat center 14px;border-top:1px solid #e9e9e9;padding-top:50px;padding-bottom:6px;text-decoration:none}.nxin-help-20JUlb a:hover{color:#ff0023}.nxin-help-20JUlb a:first-child{border-top:none}.nxin-help-20JUlb .nxin-app-1WbJEj{padding-top:0;padding-bottom:0}.nxin-help-20JUlb .nxin-zhiCustomBtn-2FAiWh{background:url(https://nfs.nxin.com/cms_image_d66c399d-dae2-4417-b5a6-60e56be7dd84.png) no-repeat center 7px}.nxin-help-20JUlb .nxin-on-line-3Q3rDF{background-position:center -320px}.nxin-help-20JUlb .nxin-questionnaire-3Vld_z{background:url(https://nfs.nxin.com/cms_image_e122810d-6843-4651-8a3b-19d84289787c.png) no-repeat center 7px;background-size:40px 40px}\n", ""]);

// exports
exports.locals = {
	"help": "nxin-help-20JUlb",
	"app": "nxin-app-1WbJEj",
	"zhiCustomBtn": "nxin-zhiCustomBtn-2FAiWh",
	"on-line": "nxin-on-line-3Q3rDF",
	"onLine": "nxin-on-line-3Q3rDF",
	"questionnaire": "nxin-questionnaire-3Vld_z"
};