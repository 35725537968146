import "classlist-polyfill";
// import jsonp from "jsonp";
import style from "./assets/css/index.scss";
import appImg from "./assets/images/app.png";

// const loc = /nxin/;
// const dev = /(\.t\.)/;
// const pre = /(\.p\.)/;
// const { hostname } = window.location;

// let open = "https://open.nxin.com";

// if (dev.test(hostname) || !loc.test(hostname)) {
//   open = "https://open.t.nxin.com";
// }

// if (pre.test(hostname)) {
//   open = "https://open.p.nxin.com";
// }

function help(cb) {
  // jsonp(`${open}/api/nxin.usercenter.user.get/1.0`, (error, response) => {
  // const { code, data } = response;

  const $help = document.createElement("div");
  $help.classList.add(style.help);

  const $content = `<a class="${style.questionnaire}" target="_blank" href="https://qaac.nxin.com/questionnaire/be14ff67b8c911ed84de48dc2d050583">有奖调研</a>
    <a href="https://znt.nxin.com/" class="${style.app}" target="_blank"><img src="${appImg}" /></a>
    <a href="https://work.weixin.qq.com/kfid/kfcd200d8c554673e56" target="_blank" class="${style.zhiCustomBtn} zhiCustomBtn">在线客服</a>
    <a class="${style.onLine}" target="_blank" href="http://help.nxin.com/">在线帮助</a>`;
  $help.innerHTML = $content;

  // const script = document.createElement("script");
  // script.type = "text/javascript";
  // script.id = "zhichiScript";

  // let scriptSrc = "https://www.sobot.com/chat/frame/js/entrance.js?sysNum=779f1457e070453298e97fd9cb676886";
  // if (code === 0) {
  //   scriptSrc += `&partnerId=${data.idStr}&params={"realName":"${data.realName}",
  // "mobilePhone":"${data.mobilePhone}","email":"${data.email}"}`;
  // }
  // script.src = scriptSrc;
  // script.setAttribute("class", "zhiCustomBtn");
  // script.setAttribute("data-args", "true");

  // $body.appendChild(script);

  const $body = document.body;
  $body.appendChild($help);

  cb && cb($help);

  // script.onload = () => {
  //   /* global getzhiSDKInstance */
  //   const zhiManager = getzhiSDKInstance();
  //   zhiManager.set("customBtn", "true");
  // };
  // });
}

window.NxinPlug = {
  ...window.NxinPlug,
  help,
};

export default { NxinPlug: { help } };
